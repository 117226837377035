import { render, staticRenderFns } from "./edgepower.vue?vue&type=template&id=38057c24&scoped=true"
import script from "./edgepower.vue?vue&type=script&lang=js"
export * from "./edgepower.vue?vue&type=script&lang=js"
import style0 from "./edgepower.vue?vue&type=style&index=0&id=38057c24&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38057c24",
  null
  
)

export default component.exports